<script>
  export default {
    data() {
      return {
        addedTimeout: null,
      }
    },
    created() {
      clearTimeout(this.addedTimeout)
      this.addedTimeout = setTimeout(() => {
        this.$navigate({ name: 'CustomizeSummary', params: { ...this.$route.params } })
      }, 2000)
    },
  }
</script>

<template>
  <section class="pt-4 pb-15 sm:pb-0">
    <BaseContainer max-width="sm">
      <div class="flex flex-col items-center">
        <BaseIcon :size="9" class="mb-3 text-primary">
          <IconCheckmarkCircle />
        </BaseIcon>
        <BaseRichText class="text-center">
          <h1 class="font-heading font-medium text-3xl md:text-5xl">Customization Complete!</h1>
          <span> Your choices have been saved. </span>
        </BaseRichText>
      </div>
    </BaseContainer>
  </section>
</template>
